.imgproducto{
    width: 100%;
}

.imgproducto img{
    width: 100%;
}

tr{
    cursor: pointer !important;
    color: black;
    opacity: 1;
    font-size: 13px !important;
    max-height: 10px !important;
}

.tablaproductos tr:hover{
    background-color: black !important;
    opacity: 0.5;
    color: white !important;
}

/* .precioderecha{
    display: inline-block;
    text-align: right !important;
} */
.pesos{
    float: left !important;
}

.vermobile .monto{
    margin-right: 10% !important;
}
.monto{
    float: right !important;
    margin-right: 50% !important;
}
.textareavariante{
    width: 100% !important;
    min-height: 150px !important;
}

.botoncantidad{
    /* width: 100% !important; */
    height: 35px !important;
    text-align: center !important;
}

td{
    padding: 2px !important;
    max-height: 10px !important;
}

input{
    width: 100%;
}

.verdetallepedidoactual{
    position: absolute;
    display: flex;
    top: 70px !important;
    z-index: 100 !important;
    position: fixed !important;
    width: auto !important;
    height: 70px !important;
    right: 0px !important;
    font-size: 17px;
}
/* .p-sidebar{
    width: 50% !important;
} */

.detallespedidosidebar{
    width: 70% !important;
}

.sidebardetallemispedidos{
    width: 70% !important;
}

.datoitemactual th{
    color: black !important;
}

.datoitemactual{
    text-align: center !important;
    font-size: 12px;
}

.confirmapedido{
    z-index: 3000;
}

.pedido_dato{
    text-align: center !important;
}

.footer_clonar{
    margin-top: 30px !important;
}

#tablaproductos {
    width: 500px !important;
    overflow-x: auto !important;
}

.sidebar-marca{
    width: 40% !important;
}

.login{
    background: 
    linear-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("img/fondologin.jpg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100% !important;
    position: absolute !important;
    top: 0px !important;
    z-index: 1000;
    left: 0px !important;
    text-align: center !important;
    height: 100% !important;
}

.login:before {
	background-color: rgba(0,0,0,0.6);
}

.loginform{
    width: 20% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 15% !important;
}

.logologin{
    text-align: center !important;
}
.logologin img{
    width: 80% !important;
}

.vaciarpedido{
    margin-left: 20px !important;
}

.img-logo img{
    width: 80% !important;
}

.layout-logo{
    padding: 20px !important;
}

.detallespedidoadmin{
    width: 50% !important;
}


.nopmbreproducto{
    text-align: left !important;
}

.camposbusqueda input, select{
    transition: all 0.3s;
    width: 100%;
    height: 100%;
    text-decoration: none !important;
}

.camposbusqueda input:hover, select:hover{    
    border: 3px solid black !important;
}

.formcliente, select, input{
    height: 35px !important;
}

.sidebar-cliente{
    width: 40% !important;
}

.imgproductohijo{
    text-align: center !important; 
    padding-top: 50px !important;     
    padding-bottom: 50px !important;     
}
.imgproductohijo img{
    max-width: 80% !important;  
    max-height: 80% !important;    
}

.datoproducto{
    /* background-color:orange; */
    
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}
.datoproductospan{
    width: 100% !important;
    height: 100% !important;
}

.inputbusc{
    width: 100%;
}


.verescritorio{
    display: block !important;
}

.vermobile{
    display: none !important;
}

.img-producto{
    width: 100%;
} 

.imgbrandcolumna{
    max-width: 150px !important;
}

.editarmarcacolumna{
    max-width: 150px !important;
}

.nombremarcacolumna{
    max-width: 100px !important;
}

.contenedorimgmarca{
    padding: 10px;
    width: 100%;
    text-align: center !important;
}
.brand-image{
    height: 50px;
}

.contadoritems{
    font-size: 15px !important;
    width: 20px !important;
    height: 20px !important;
}

.w-70px{
    width: 70px !important;
}

.w-80px{
    width: 80px !important;
}

.w-100px{
    width: 100px !important;
}

.w-150px{
    width: 150px !important;
}

.verescritorio .datatable th{
    text-align: center !important;
    padding: 0px !important;
}

.t-a-left{
    text-align: left !important;
}

.vermobile .botoneliminaritem, .vermobile .botoneditaritem{
    width: 32px !important;
    height: 32px !important;
}

.tablaancha .p-datatable-wrapper{
    overflow-x: scroll !important;
}

.tablaancha .p-datatable-wrapper table{
    
    width: 150% !important;
}

#contenedordatosproducto{
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.25);
}


.nombreusuario{
    margin-top: 20px !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

td.texto-derecha{
    text-align: right !important;
    padding-right: 30px !important;
}